 import * as React from "react"
 import { useStaticQuery, graphql } from "gatsby"
 import { StaticImage } from "gatsby-plugin-image"
 
 const Bio = () => {
   const data = useStaticQuery(graphql`
     query NewBioQuery {
       site {
         siteMetadata {
           author {
             name
             summary
             email
           }
           social {
             twitter
           }
         }
       }
     }
   `)
 
   // Set these values by editing "siteMetadata" in gatsby-config.js
   const author = data.site.siteMetadata?.author
 
   return (
     <div className="new-bio">
       <StaticImage
         className="new-bio-avatar"
         layout="fixed"
         formats={["AUTO", "WEBP", "AVIF"]}
         src="../images/budragon-icon.png"
         width={50}
         height={50}
         quality={95}
         alt="web picture"
       />
       {author?.name && (
         <p>
           I'm <strong>{author.name}</strong> 
           {` `}
           <br />
           {author.summary}
         </p>
       )}
     </div>
   )
 }
 
 export default Bio
 